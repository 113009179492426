<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByPortAuthorityId"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<bollardnoray-modals :modelName="modelName"></bollardnoray-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import bollardnorayActions from './BollardnorayActions';
import bollardnorayModals from './BollardnorayModals.vue';

export default {
	name: 'bollardnoray-grid',
	components: {
		'bollardnoray-modals': bollardnorayModals
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'bollardnoray',
			actions: bollardnorayActions.gridactions,
			modelColumnDefs: {
				thegeom: {
					render: (data, type, row) => {
						return row.thegeom ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	computed: {
		filterByPortAuthorityId() {
			const portId = window.localStorage.getItem('workingPortsId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_id', op: 'in', data: portId }]
			};
		}
	}
};
</script>
